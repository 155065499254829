import React from 'react'
import Link from 'gatsby-link'
import Obfuscate from 'react-obfuscate'

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="info-area">
        <div className="column">
          <a href="https://www.immowelt.de/profil/d930f1e4973a4ef6b76271afe1540a57" target="_blank" rel="noopener noreferrer">Vogl-Immobilien bei Immowelt</a>
        </div>
        <div className="column">
          <Link to="/impressum" exact="true">Impressum</Link>
          <Link to="/datenschutz" exact="true">Datenschutz</Link>
        </div>
        <div className="column">
          <p>
            <strong>Vogl-Immobilien</strong><br/>
            Rathmecker Weg 7<br/>
            58513 Lüdenscheid<br/><br/>
            E-Mail: <Obfuscate linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." email="frank-vogl@t-online.de" /><br/>
            Telefon: <Obfuscate linkText="Geschützt - Bitte erneut mit der Maus überfahren oder klicken." tel="0151-64717999" />
          </p>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <p>Vogl-Immobilien © { new Date().getFullYear() }</p>
    </div>
  </footer>
)

export default Footer
