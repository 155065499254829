import React from 'react'
import Link from 'gatsby-link'
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'
import GatsbyConfig from '../../gatsby-config'
//import MyBurgerMenu from '../components/MyBurgerMenu';
//import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'react-responsive';
//import React, { Component } from 'react'
import Select from 'react-select'

//const isBurgerMenuNeeded = useMediaQuery({ query: '(max-width: 1224px)' })

/*
<MediaQuery query="(max-width: 1224px)">
      <MyBurgerMenu />
    </MediaQuery>
*/

/*
min-device-width:
max-width:
max-device-width:
orientation:
min-resolution:
*/
const options = [
  { value: 'startseite', label: <Link to="/" exact="true" activeClassName="active">Startseite</Link> },
  { value: 'immobilien', label: <Link to="/immobilien" exact="true" activeClassName="active">Immobilien</Link> },
  { value: 'kontakt', label: <Link to="/kontakt" exact="true" activeClassName="active">Kontakt</Link> },
  { value: 'kundenbewertung', label: <Link to="/kundenbewertungen" exact="true" activeClassName="active">Kundenbewertungen</Link> },
  { value: 'zumeinerperson', label: <Link to="/zumeinerperson" exact="true" activeClassName="active">Zu meiner Person</Link> },
]

/*const MyComponent = () => (
  <Select options={options} />

<Link to="/" exact="true" activeClassName="active">
  Menu
</Link>
)*/

const Header = ({ siteTitle }) => (
  <div className="nav-bar">
    <Helmet
      title={GatsbyConfig.siteMetadata.title}
      meta={[
        { name: 'description', content: 'Vogl-Immobilien' },
        {
          name: 'keywords',
          content: 'Vogl-Immobilien, Vogl Immobilien, Immobilienmakler, Makler, Lüdenscheid, Märkischen Kreis, Immobilien, Kauf, Häuser, Wohnungen',
        },
      ]}
      link={[{ rel: 'shortcut icon', type: 'image/ico', href: `${favicon}` }]}
    />

    {
      /**
       * <nav class="menu"> um ul/li
       */
    }
    {
      //isBurgerMenuNeeded = useMediaQuery({ query: '(max-width: 1224px)' })
    }

    {
      //checkBurgerMenuPrint(isBurgerMenuNeeded)
    }

    {
      /*
      <MediaQuery query="(max-width: 974px)">
        <MyBurgerMenu />
      </MediaQuery>
      */
    }
    <div className="container">
      <div className="nav-items">
        <span className="brand">{siteTitle}</span>
        <MediaQuery query="(max-width: 700px)">
          <ul>
            <li>
            <Select options={options} className='react-select-container' isSearchable='0' />
            </li>
          </ul>
        </MediaQuery>

        <MediaQuery query="(min-width: 701px)">
        <ul>
          <li>
            <Link to="/" exact="true" activeClassName="active">
              Startseite
            </Link>
          </li>
          <li>
            <Link to="/immobilien" exact="true" activeClassName="active">
              Immobilien
            </Link>
          </li>
          <MediaQuery query="(min-width: 730px)">
          <li>
            <Link to="/kontakt" exact="true" activeClassName="active">
              Kontakt
            </Link>
          </li>
          </MediaQuery>
          <MediaQuery query="(min-width: 1050px)">
          <li>
            <Link to="/kundenbewertungen" exact="true" activeClassName="active">
              Kundenbewertungen
            </Link>
          </li>
          </MediaQuery>
          <MediaQuery query="(min-width: 1357px)">
          <li>
            <Link to="/zumeinerperson" exact="true" activeClassName="active">
              Zu meiner Person
            </Link>
          </li>
          </MediaQuery>
        </ul>
        </MediaQuery>
      </div>
    </div>
  </div>
)

/*function checkBurgerMenuPrint(isBurgerMenuNeeded) {
  if (isBurgerMenuNeeded) {
    return <MyBurgerMenu /> ;
  }
  return <p>False</p>;
}*/

export default Header
