module.exports = {
  siteMetadata: {
    title: 'Vogl-Immobilien - Ihr Immobilienmakler im Märkischen Kreis',
    description: 'Vogl-Immobilien ist Ihr Immobilienmakler im Märkischen Kreis für Ihr neues Eigentum. Informieren Sie sich über aktuelle Häuser und Wohnungen.',
    siteUrl: 'http://vogl-immobilien.com',
    immobilien: {
      title: 'Vogl-Immobilien - Immobilien',
      siteUrl: 'http://vogl-immobilien.com/immobilien',
    },
    kontakt: {
      title: 'Vogl-Immobilien - Kontakt',
      siteUrl: 'https://sosc.org.in/kontakt',
    },
    kundenbewertungen: {
      title: 'Vogl-Immobilien - Kundenbewertungen',
      siteUrl: 'http://vogl-immobilien.com/kundenbewertungen',
    },
    zumeinerperson: {
      title: 'Vogl-Immobilien - Zu meiner Person',
      siteUrl: 'http://vogl-immobilien.com/zumeinerperson',
    },
  },
  plugins: [
    'gatsby-plugin-react-helmet',
    `gatsby-plugin-sass`,
    `gatsby-remark-copy-linked-files`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-images`,
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 1080,
            },
          },
        ],
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src`,
        name: 'images',
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
    },
    {
      resolve: `gatsby-plugin-nprogress`,
      options: {
        // Setting a color is optional.
        color: `blue`,
        // Disable the loading spinner.
        showSpinner: false,
      },
    },
  ],
  //pathPrefix: `/newimmo`,
}
